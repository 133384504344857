.gx-popover-lang-scroll {
  height: 60px !important;
}

.gx-card-image-container {
  height: 190px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c5dbd8;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  table-layout: fixed;
  padding: 0;
  cursor: pointer;
}

.custom-table th,
.custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-size: 10px;
}

.custom-table th {
  background-color: #f2f2f2;
}

.custom-table tr:hover {
  background-color: #f5f5f5;
}

.ant-col-no-padding {
  padding: 0;
}

.custom-table td {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-table tr {
  height: 40px;
  margin-bottom: 5px;
}

.filter-green {
  filter: invert(38%) sepia(47%) saturate(1896%) hue-rotate(142deg) brightness(92%) contrast(101%);
  height: 110px;
}

.gx-card-widget {
  border-radius: 8px !important;
}

.gx-card-widget-high-border-radius {
  border-radius: 20px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.ml0 {
  margin-left: 0px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.pt0 {
  padding-top: 0px !important;
}

.pr0 {
  padding-right: 0px !important;
}

.pl0 {
  padding-left: 0px !important;
}

.pl10 {
  padding-left: 10px !important;
}

/* .ant-row {
  margin-right: -17px !important;
} */

.gx-header-horizontal-top {
  padding-bottom: 0px;
}

.gx-header-horizontal-main {
  padding: 15px 0;
}

.ant-menu-dark .ant-menu-item-selected>a,
.ant-menu-dark .ant-menu-item-selected>span>a {
  color: white;
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #003366;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #003366;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-left::before {
  top: 0;
  right: 0;
}

.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}

.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}

.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}

.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}

.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}

.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}

.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}

.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}

.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}

.full-width-search .ant-select-auto-complete {
  width: 100% !important;
}

.ant-carousel .slick-dots li button {
  background-color: #003366 !important;
}

@media print {
  body.full-scroll #root {
    overflow: unset !important;
  }
}

.full-width {
  width: 100%;
}

.full-width .ant-radio-button-wrapper {
  width: 50%;
  text-align: center;
}

iframe {
  position: unset !important;
}

.ant-drawer-body {
  overflow-y: hidden;
}

.gx-popover-horizantal .ant-btn {
  height: 34px;
}

body {
  font-family: "Roboto", sans-serif;
}


table.no-border-bottom tr:last-child td {
  border-bottom: none;
}

table.no-border-bottom tr td {
  padding: 4px;
}

@media (max-width: 575px) {
  .responsive-col {
    padding: 8px;
  }

  .gx-card-widget .ant-card-body {
    padding: 10px;
  }
}

.flag-icon-background-news {
  background: blue;
  border: 1px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}